// Import libraries
import { Egg } from "@mui/icons-material";
import {AirCraft, PLANE} from "./AircraftData.js"
import parseISO from "date-fns/parseISO";

// Import CSS, images, icons and static data
import background from "../flights/flightData/FlightData.js"


// ************************* MAIN FUNCTION **************************************

export const CreateAircraftList = (planeList, serviceEvents) => {
    // Initialise aircraft list with default PLANE data
    let aircraftList = [PLANE];
        // console.log("In CreateAircraftList, planelist->", planeList);
    // Construct the Oil Levels, Defects and Service Events for each plane
    planeList.forEach((plane) => {
        // Oil Levels
            // console.log("In CreateAircraftList, plane->", plane);
        let oilLevelsRaw = Object.values(plane.planeMakeAndModel.oilLevels).slice(1);
            // let oilLevels = oilLevelsRaw.slice(1);
        let oilLevels = [" "];
        oilLevelsRaw.forEach((level) => {
            oilLevels.push(level);
        });
        // console.log("oilLevels ->", oilLevels)
        //  console.log("In CreateAircraftList, plane->", plane)
        // Defects
        let defects = [];

        plane.defects.forEach((defect) => {
            if (defect.defectStatus === "OPEN") {
                defects.push(defect);
            }  
        });

        // Populate the Service Data - Next Service Data & Next Service Hours
        let currentServiceEvent = "";
            serviceEvents.forEach((serviceEvent) => {
                if (serviceEvent.plane.registration === plane.registration) {
                    currentServiceEvent = serviceEvent;
                }
            });
            // console.log("In CreateAircraftList, currentServiceEvent->",currentServiceEvent);
        
        aircraftList.push(new AirCraft(
            // Registration details (4)
            plane.id,    
            plane.registration, 
            plane.registeringAuthority.name, 
            plane.colour, 
            // Make & model details (3)
            plane.planeMakeAndModel.id,
            plane.planeMakeAndModel.make, 
            plane.planeMakeAndModel.model, 
            plane.planeMakeAndModel.planeComplexity,
            plane.planeMakeAndModel.planeType,
            plane.planeMakeAndModel.numberOfEngines,
            // Fuel & oil (9)
            oilLevels, 
            plane.planeMakeAndModel.fuelType.label,
            plane.fuelTank1Capacity,
            plane.fuelTank2Capacity,
            plane.fuelTank3Capacity,
            plane.fuelTank4Capacity,
            plane.fuelTank1State,
            plane.fuelTank2State,
            plane.fuelTank3State,
            plane.fuelTank4State,
            // Maintenance details (4)
            parseISO(currentServiceEvent.dateOfNextService, "YYYY-MM-DD"),
            currentServiceEvent.nextServiceHours,
            plane.currentHours,
            plane.currentSvc,
            defects
            ))});
        
        // console.log("In CreateAircraftList, aircraftList->", aircraftList)
    return aircraftList;
}

// export default CreateAircraftList;
 