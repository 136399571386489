// // Import libraries
import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import {Box, Button} from "@mui/material"
import EnrollmentBannerLogo from "./EnrollmentBannerLogo.js";
import EnrollmentBannerTitle from "./EnrollmentBannerTitle.js";
import { PersonSelect } from "../admin/person/PersonSelect.js";
import DatabaseConnectionWarning from "../utilities/database/DatabaseConnectionWarning.js";
import { useMediaQuery } from "@mui/material";


// // Import CSS & images
import "./Course.css";
import { mobileThreshold } from "../utilities/environment/EnvironmentData";
import azTechTheme from "../utilities/environment/ColourTheme";
// import azTechTheme from "../../utilities/environment/ColourTheme.js";

// //Import fonts
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';




// // ******************************* MAIN FUNCTION ************************************************
export default function AdminEnrollmentBanner(props) {
    const calledFrom = "CourseMain"
    const [switchClass, setSwitchClass] = useState("switch");
    const [direction, setDirection] = useState("row")
    const [justification, setJustification] = useState("flex-end")
    var media = useMediaQuery(`(min-width:${mobileThreshold}px)`);
    const backgroundColour = azTechTheme.palette.blanchedAlmond;

    const navigate = useNavigate();
    // const handleClick = (path) => {
        // console.log("In EnrollmentBanner, handleMenuClick, path->", path);
        // navigate(path);
    // }
//         // console.log("In EditSwitchCourse, useEffect triggered to->", props.editingSwitch);
//     // console.log("In AdminCourseBanner, props.courseList->", props.courseList);

    useEffect (() => {
        if (media) {
            setSwitchClass("switch")
            setDirection("row")
            setJustification("flex-end")
          } else {
            setSwitchClass("switch")
            setDirection("column")
            setJustification("flex-bottom")
          } 
        }, [media]);

//         // console.log("In AdminCourseBanner, props.isEdited->", props.isEdited);

    // Render
    return (
        <Box className = "banner" sx = {{backgroundColor: backgroundColour}}>
            <Box className = "bannerRow1" sx = {{backgroundColor: backgroundColour}}>
                <Box sx = {{display: "flex", ml: "10px", flexDirection: "row", selfJustify: "flexStart"}}>
                    <EnrollmentBannerTitle title = {props.title}/>
                    <Box className = "databaseConnectionWarning">
                            <DatabaseConnectionWarning databaseConnection = {props.databaseConnection}/>
                    </Box>  
                </Box>
                <div className = "logo"><EnrollmentBannerLogo/></div>
            </Box>
            <Box className = "banner-row-admin" >
                <Box className = "course-select-admin" > 
                    <PersonSelect
                        calledFrom = {calledFrom}
                        databaseConnection = {props.databaseConnection} 
                        selectedPerson = {props.selectedPerson}
                        // courseName = {props.selectedCourse.name} 
                        // newCourse = {props.newCourse}
                        enrollmentList = {props.enrollmentList}
                        UpdateSelectedPerson={props.UpdateSelectedPerson}/>
                </Box>
                {/* <Box sx = {{color: "red", ml: "10px", mr: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-end", fontSize: "large"}}>
                    UNDER CONSTRUCTION
                </Box> */}
                <Box sx = {{color: "black", justifyContent: "end"}}>
                    {props.selectedCourse.name}
                </Box>
                <Box>
                    {/* <EditSwitchEnrollment 
                        UpdateEditing = {props.UpdateEditing}
                        UpdateEditingSwitch = {props.UpdateEditingSwitch}
                        editingSwitch = {props.editingSwitch}
                        isEdited = {props.isEdited}
                        UpdateIsEdited = {props.UpdateIsEdited}
                        CourseSubmit = {props.CourseSubmit} 
                        selectedCourse = {props.selectedCourse}
                        UpdateSelectedCourse={props.UpdateSelectedCourse}
                        HandleReset = {props.HandleReset}>
                    </EditSwitchEnrollment> */}
                </Box>
            </Box>
        </Box>
    );
};
